<template>
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <a
      class="sidebar-brand d-flex align-items-center justify-content-center"
      href="/dashboard"
      style="background-color: white"
    >
      <!-- <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas fa-laugh-wink"></i>
      </div> -->
      <img
        src="../../assets/amazon.png"
        style="height: 35px; width: 160px"
        alt="LOGO"
      />
      <!-- <span class="ms-1 font-weight-bold">Innovative Toll</span> -->
      <!-- <div class="sidebar-brand-text mx-3">Innovative Toll</div> -->
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <li class="nav-item pl-1">
      <!-- <a class="nav-link" href="/dashboard"> -->
      <router-link class="nav-link" to="/dashboard"
        ><i class="fas fa-fw fa-tachometer-alt"></i>
        <span>DASHBOARD</span></router-link
      >
    </li>

    <!-- Heading -->
    <!-- Nav Item - Pages Collapse Menu -->

    <hr class="sidebar-divider d-none d-md-block" />
    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapsePages"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-fw fa-toolbox"></i>
        <span>TRANSPONDER</span>
      </a>
      <div
        id="collapsePages"
        style="font-size: small !important"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Transponder Modules:</h6>

          <router-link class="collapse-item text-sm" to="/all-transponder"
            >Amazon Transponder
          </router-link>

          <router-link
            class="collapse-item text-sm"
            style="font-size: small !important"
            to="/transponder-fulfillment"
            >Transponder Fulfillment</router-link
          >
        </div>
      </div>
    </li>
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapsePages1"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-fw fa-truck"></i>
        <span>VEHICLE</span>
      </a>
      <div
        id="collapsePages1"
        style="font-size: small !important"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Vehicle Modules:</h6>

          <router-link class="collapse-item text-sm" to="/asset-masterfile"
            >Asset Masterfile</router-link
          >
          <!-- <router-link
            class="collapse-item text-sm"
            style="font-size: small !important"
            to="/vehicle"
            >Vehicles</router-link
          > -->
          <!-- <router-link
            class="collapse-item text-sm"
            style="font-size: small !important"
            to="/transponder-fulfillment"
            >Client Uploads</router-link
          > -->
          <!-- <router-link
            class="collapse-item text-sm"
            style="font-size: small !important"
            to="/transponder-fulfillment"
            >Logs</router-link
          > -->
        </div>
      </div>
    </li>
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapsePages2"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-fw fa-money-bill"></i>
        <span>TRANSACTION</span>
      </a>
      <div
        id="collapsePages2"
        style="font-size: small !important"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Transactions Modules:</h6>

          <router-link class="collapse-item text-sm" to="/weekly-report"
            >Weekly Report</router-link
          >
          <router-link
            class="collapse-item text-sm"
            style="font-size: small !important"
            to="/transactions"
            >Toll Transactions
          </router-link>
        </div>
      </div>
    </li>
    <!-- <li class="nav-item">
      <router-link to="/citation" class="nav-link collapsed" href="#">
        <i class="fas fa-fw fa-exclamation"></i>
        <span>CITATION</span>
      </router-link>
    </li> -->

    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapsePages3"
        aria-expanded="true"
        aria-controls="collapsePages"
      >
        <i class="fas fa-fw fa-folder"></i>
        <span>MISCELLANEOUS</span>
      </a>
      <div
        id="collapsePages3"
        style="font-size: small !important"
        class="collapse"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Other Modules:</h6>

          <router-link class="collapse-item text-sm" to="/state"
            >State</router-link
          >
          <router-link
            class="collapse-item text-sm"
            style="font-size: small !important"
            to="/agency"
          >
            Agencies
          </router-link>
        </div>
      </div>
    </li>
    <hr class="sidebar-divider d-none d-md-block" />

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button
        class="rounded-circle border-0"
        id="sidebarToggle"
        @click="goBack"
      ></button>
    </div>
  </ul>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      role: "",
      // username: "",
    };
  },
  methods: {
    goBack() {
      // Navigate back in the browser history
      window.history.back();
      window.location.reload();
    },
  },
  created() {},
};
</script>
