<template>
  <div id="wrapper">
    <SideBar />
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <Topbar />
        <!-- Begin Page Content -->
        <div class="container-fluid">
          <div class="h-100 d-flex justify-content-between">
            <h1 class="h3 mb-0 text-gray-800">
              <i class="fa fa-ticket text-sm opacity-10 text-primary"></i>
              Dashboard
            </h1>
          </div>
          <hr class="mt-2 mb-4" />
          <h6 class="mb-0 mt-4 text-gray-600 pl-2">
            View statistical data for the year and week selected
          </h6>
          <div class="row pt-3 pb-5">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-9"></div>
                        <div class="col-lg-3 col-sm-12 pb-3">
                          <div class="input-group mb-3">
                            <span id="basic-addon3">
                              <button
                                type="button"
                                class="btn btn-dark"
                                @click="updateReports()"
                              >
                                <i class="fas fa-calendar"></i>
                              </button>
                            </span>
                            <Field
                              name="weekId"
                              v-model="this.week_id"
                              class="form-control"
                              as="select"
                            >
                              <option
                                selected="selected"
                                disabled="disabled"
                                value=""
                              >
                                --Select week--
                              </option>
                              <option
                                class="text-uppercase"
                                v-for="week in getWeeks"
                                :value="week.week_id"
                                :key="week.week_id"
                              >
                                {{ week.week_name }}
                              </option>
                            </Field>
                            <input
                              type="text"
                              v-model="year"
                              class="form-control"
                              id="yearPicker"
                              ref="yearPicker"
                              placeholder="YYYY"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xxl-3 col-md-3">
                          <div class="card info-card sales-card">
                            <div class="card-body">
                              <h6 class="card-title">
                                YTD Asset Count <span></span>
                              </h6>
                              <div
                                class="d-flex align-items-center justify-content-between"
                              >
                                <div>
                                  <img
                                    alt="Profile"
                                    src="../assets/truck.png"
                                  />
                                </div>
                                <div class="ps-3">
                                  <h3 style="font-weight: bolder">
                                    {{
                                      this.ytdTollSummary?.ytdOwnedAssetCount
                                    }}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-xxl-3 col-md-3">
                          <div class="card info-card revenue-card">
                            <div class="card-body">
                              <h6 class="card-title">
                                YTD Rental Asset <span></span>
                              </h6>
                              <div
                                class="d-flex align-items-center justify-content-between"
                              >
                                <div>
                                  <img
                                    alt="Profile"
                                    src="../assets/toll.png"
                                    style="width: 63.99px; height: 63.99px"
                                  />
                                </div>
                                <div class="ps-3">
                                  <h3 style="font-weight: bolder">
                                    {{
                                      this.ytdTollSummary?.ytdRentalAssetCount
                                    }}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-xxl-3 col-md-3">
                          <div class="card info-card customers-card">
                            <div class="card-body">
                              <h6 class="card-title">Citation <span></span></h6>
                              <div
                                class="d-flex align-items-center justify-content-between"
                              >
                                <div>
                                  <img
                                    alt="Profile"
                                    src="../assets/money.png"
                                    style="width: 63.99px; height: 63.99px"
                                  />
                                </div>
                                <div class="ps-3">
                                  <h3 style="font-weight: bolder">
                                    {{ this.ytdTollSummary?.ytdCitationsCount }}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-xxl-3 col-md-3">
                          <div class="card info-card sales-card">
                            <div class="card-body">
                              <h6 class="card-title">Impound <span></span></h6>
                              <div
                                class="d-flex align-items-center justify-content-between"
                              >
                                <div>
                                  <div>
                                    <img
                                      alt="Profile"
                                      src="../assets/transaction.png"
                                      style="width: 63.99px; height: 63.99px"
                                    />
                                  </div>
                                </div>
                                <div class="">
                                  <h3 style="font-weight: bolder">
                                    {{ this.ytdTollSummary?.impoundCount }}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row pt-4">
                        <div class="col-lg-3 col-sm-12">
                          <div class="card">
                            <div class="card-header">
                              <h6 class="card-title">
                                YTD TOLL SPENDING SUMMARY {{ this.year }}
                              </h6>
                            </div>
                            <div class="card-body">
                              <dl class="row pt-3">
                                <dt class="col-sm-7 ml-auto mr-auto">
                                  <h6 style="font-weight: bold">
                                    Total YTD Toll Spend
                                  </h6>
                                </dt>
                                <dd class="col-sm-4 text-right">
                                  {{ this.ytdTollSummary?.ytdTollSpend }}
                                </dd>
                              </dl>

                              <hr />
                              <!-- Divider to separate total from breakdown -->

                              <div class="card mt-3 p-3">
                                <h6 class="text-center">Summary:</h6>
                                <!-- Subtitle for the breakdown -->
                                <dd class="separator-dashed col-12"></dd>
                                <dl class="row pt-3">
                                  <dt
                                    class="col-sm-7 ml-auto mr-auto d-flex align-items-center"
                                  >
                                    <span class="icon-circle">
                                      <i class="fa fa-truck"></i>
                                    </span>
                                    Trailers
                                  </dt>
                                  <dd class="col-sm-4 text-right pr-5">
                                    {{ this.ytdTollSummary?.ytdTrailersSpend }}
                                  </dd>
                                  <dd class="separator-dashed col-12"></dd>
                                  <dt
                                    class="col-sm-7 ml-auto mr-auto d-flex align-items-center"
                                  >
                                    <span class="icon-circle">
                                      <i class="fa fa-car"></i>
                                    </span>
                                    AFP
                                  </dt>
                                  <dd class="col-sm-4 text-right pr-5">
                                    {{ this.ytdTollSummary?.ytdAfpSpend }}
                                  </dd>
                                  <dd class="separator-dashed col-12"></dd>
                                  <dt
                                    class="col-sm-7 ml-auto mr-auto d-flex align-items-center"
                                  >
                                    <span class="icon-circle">
                                      <i class="fa fa-bus"></i>
                                    </span>

                                    TOM
                                  </dt>
                                  <dd class="col-sm-4 text-right pr-5">
                                    {{ this.ytdTollSummary?.ytdTomSpend }}
                                  </dd>
                                  <dd class="separator-dashed col-12"></dd>
                                  <dt
                                    class="col-sm-7 ml-auto mr-auto d-flex align-items-center"
                                  >
                                    <span class="icon-circle">
                                      <i class="fas fa-shuttle-van"></i>
                                    </span>
                                    DSP-MMBT
                                  </dt>
                                  <dd class="col-sm-4 text-right pr-5">
                                    {{ this.ytdTollSummary?.ytdDspMMbtSpend }}
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-9 col-sm-12">
                          <div class="card">
                            <div class="card-header">
                              <h6 class="card-title">
                                {{ this.year }} WEEK {{ this.week_id }} AGENCY
                                TOLL SPENDING <span></span>
                              </h6>
                            </div>
                            <div class="card-body">
                              <div class="row pt-5">
                                <div
                                  class="col-lg-12 col-xxl-12 col-md-12 col-12"
                                >
                                  <div id="chart">
                                    <Bar
                                      style="height: 410px !important"
                                      :data="chartData1"
                                      :options="chartBarOptions"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row pt-4">
                        <div class="col-lg-5">
                          <div class="card">
                            <div class="card-header">
                              <h6 class="card-title">
                                {{ this.year }} Week {{ this.week_id }} Agency
                                Toll Spending <span></span>
                              </h6>
                            </div>
                            <div class="card-body">
                              <div class="row pt-5">
                                <div
                                  class="col-lg-12 col-xxl-12 col-md-12 col-12"
                                >
                                  <div id="chart" v-if="series.length > 0">
                                    <apexchart
                                      ref="chart"
                                      type="donut"
                                      :options="chartOptions"
                                      :series="series"
                                    />
                                  </div>
                                  <div
                                    v-else
                                    class="d-flex justify-content-center text-center"
                                  >
                                    <div>
                                      <img
                                        alt="Profile"
                                        style="width: 90px; height: 90px"
                                        src="../assets/pie.png"
                                      />
                                      <h4>No data available!</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="card">
                            <div class="card-header">
                              <h6 class="card-title">
                                YTD Toll Spending {{ this.year }} <span></span>
                              </h6>
                            </div>
                            <div class="card-body">
                              <div class="row pt-5">
                                <div
                                  class="col-lg-12 col-xxl-12 col-md-12 col-12"
                                >
                                  <div id="chart">
                                    <Bar
                                      style="height: 510.67px !important"
                                      :data="chartData"
                                      :options="chartBar2Options"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row pt-4">
                        <div class="col-lg-12 col-md-12">
                          <div class="card mb-3">
                            <div class="card-header">
                              <h6 class="card-title">
                                WEEK {{ this.week_id }} Top Vehicles<span
                                ></span>
                              </h6>
                            </div>
                            <div class="card-body pt-3">
                              <ul
                                class="nav nav-tabs"
                                id="myTab"
                                role="tablist"
                                style="font-size: 14px"
                              >
                                <li class="nav-item" role="presentation">
                                  <button
                                    class="nav-link active"
                                    id="home-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#home"
                                    type="button"
                                    role="tab"
                                    aria-controls="home"
                                    aria-selected="true"
                                    v-on:click="statusChange(0)"
                                  >
                                    AFP Vehicle
                                  </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button
                                    class="nav-link"
                                    id="profile-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="profile"
                                    aria-selected="false"
                                    v-on:click="statusChange(1)"
                                  >
                                    Power Unit Vehicle
                                  </button>
                                </li>

                                <li class="nav-item" role="presentation">
                                  <button
                                    class="nav-link"
                                    id="contact-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#contact"
                                    type="button"
                                    role="tab"
                                    aria-controls="contact"
                                    aria-selected="false"
                                    v-on:click="statusChange(2)"
                                  >
                                    Trailer Vehicles
                                  </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button
                                    class="nav-link"
                                    id="tom-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tom"
                                    type="button"
                                    role="tab"
                                    aria-controls="tom"
                                    aria-selected="false"
                                    v-on:click="statusChange(3)"
                                  >
                                    Tom Vehicles
                                  </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button
                                    class="nav-link"
                                    id="dsp-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#dsp"
                                    type="button"
                                    role="tab"
                                    aria-controls="dsp"
                                    aria-selected="false"
                                    v-on:click="statusChange(4)"
                                  >
                                    DSP MMBT Vehicles
                                  </button>
                                </li>
                              </ul>
                              <div
                                class="tab-content pt-2"
                                id="myTabContent"
                                style="
                                  display: flex;
                                  flex-direction: column;
                                  width: 100%;
                                "
                              >
                                <div
                                  class="tab-pane fade show active"
                                  id="home"
                                  role="tabpanel"
                                  aria-labelledby="home-tab"
                                  style="flex: 1"
                                >
                                  <div
                                    class="table-responsive pt-3"
                                    v-if="status == 0"
                                    style="width: 100%"
                                  >
                                    <table
                                      id="reportTable0"
                                      class="table table-bordered table-sm"
                                      style="width: 100%"
                                      v-if="reportdata0.length > 0"
                                    >
                                      <thead
                                        style="
                                          background-color: #d2e8fa;
                                          color: whitesmoke;
                                          text-transform: uppercase;
                                        "
                                      >
                                        <tr>
                                          <th>Asset type</th>
                                          <th>LP</th>
                                          <th>Transponder</th>
                                          <th>State</th>
                                          <th>Owner</th>
                                          <th>Equipment ID</th>
                                          <th>Amount</th>
                                        </tr>
                                      </thead>
                                    </table>
                                    <div
                                      v-else
                                      class="d-flex justify-content-center text-center pt-4"
                                    >
                                      <div>
                                        <img
                                          src="../assets/data.png"
                                          alt="Profile"
                                          style="width: 90px; height: 90px"
                                        />
                                        <h4>No data available!</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="tab-pane fade"
                                  id="profile"
                                  role="tabpanel"
                                  aria-labelledby="profile-tab"
                                  style="flex: 1"
                                >
                                  <div
                                    class="table-responsive pt-3"
                                    v-if="status == 1"
                                    style="width: 100%"
                                  >
                                    <table
                                      id="reportTable1"
                                      class="table table-bordered table-sm"
                                      style="width: 100%"
                                      v-if="this.reportdata1.length > 0"
                                    >
                                      <thead
                                        style="
                                          background-color: #d2e8fa;
                                          color: whitesmoke;
                                          text-transform: uppercase;
                                        "
                                      >
                                        <tr>
                                          <th>Asset type</th>
                                          <th>LP</th>
                                          <th>Transponder</th>
                                          <th>State</th>
                                          <th>Owner</th>
                                          <th>Equipment ID</th>
                                          <th>Amount</th>
                                        </tr>
                                      </thead>
                                    </table>
                                    <div
                                      v-else
                                      class="d-flex justify-content-center text-center pt-4"
                                    >
                                      <div>
                                        <img
                                          src="../assets/data.png"
                                          alt="Profile"
                                          style="width: 90px; height: 90px"
                                        />
                                        <h4>No data available!</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="tab-pane fade"
                                  id="contact"
                                  role="tabpanel"
                                  aria-labelledby="contact-tab"
                                  style="flex: 1"
                                >
                                  <div
                                    class="table-responsive pt-3"
                                    v-if="status == 2"
                                    style="width: 100%"
                                  >
                                    <table
                                      id="reportTable2"
                                      class="table table-bordered table-sm"
                                      style="width: 100%"
                                      v-if="reportdata2.length > 0"
                                    >
                                      <thead
                                        style="
                                          background-color: #d2e8fa;
                                          color: whitesmoke;
                                          text-transform: uppercase;
                                        "
                                      >
                                        <tr>
                                          <th>Asset type</th>
                                          <th>LP</th>
                                          <th>Transponder</th>
                                          <th>State</th>
                                          <th>Owner</th>
                                          <th>Equipment ID</th>
                                          <th>Amount</th>
                                        </tr>
                                      </thead>
                                    </table>
                                    <div
                                      v-else
                                      class="d-flex justify-content-center text-center pt-4"
                                    >
                                      <div>
                                        <img
                                          src="../assets/data.png"
                                          alt="Profile"
                                          style="width: 90px; height: 90px"
                                        />
                                        <h4>No data available!</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="tab-pane fade"
                                  id="tom"
                                  role="tabpanel"
                                  style="flex: 1"
                                  aria-labelledby="tom-tab"
                                >
                                  <div
                                    class="table-responsive pt-3"
                                    v-if="status == 3"
                                  >
                                    <table
                                      id="reportTable3"
                                      class="table table-bordered table-sm"
                                      style="width: 100%"
                                      v-if="reportdata3.length > 0"
                                    >
                                      <thead
                                        style="
                                          background-color: #d2e8fa;
                                          color: whitesmoke;
                                          text-transform: uppercase;
                                        "
                                      >
                                        <tr>
                                          <th>Asset type</th>
                                          <th>LP</th>
                                          <th>Transponder</th>
                                          <th>State</th>
                                          <th>Owner</th>
                                          <th>Equipment ID</th>
                                          <th>Amount</th>
                                        </tr>
                                      </thead>
                                    </table>
                                    <div
                                      v-else
                                      class="d-flex justify-content-center text-center pt-4"
                                    >
                                      <div>
                                        <img
                                          src="../assets/data.png"
                                          alt="Profile"
                                          style="width: 90px; height: 90px"
                                        />
                                        <h4>No data available!</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="tab-pane fade"
                                  id="dsp"
                                  role="tabpanel"
                                  aria-labelledby="dsp-tab"
                                  style="flex: 1"
                                >
                                  <div
                                    class="table-responsive pt-3"
                                    v-if="status == 4"
                                    style="width: 100%"
                                  >
                                    <table
                                      id="reportTable4"
                                      class="table table-bordered table-sm"
                                      style="width: 100%"
                                      v-if="reportdata4.length > 0"
                                    >
                                      <thead
                                        style="
                                          background-color: #d2e8fa;
                                          color: whitesmoke;
                                          text-transform: uppercase;
                                        "
                                      >
                                        <tr>
                                          <th>Asset type</th>
                                          <th>LP</th>
                                          <th>Transponder</th>
                                          <th>State</th>
                                          <th>Owner</th>
                                          <th>Equipment ID</th>
                                          <th>Amount</th>
                                        </tr>
                                      </thead>
                                    </table>
                                    <div
                                      v-else
                                      class="d-flex justify-content-center text-center pt-4"
                                    >
                                      <div>
                                        <img
                                          src="../assets/data.png"
                                          alt="Profile"
                                          style="width: 90px; height: 90px"
                                        />
                                        <h4>No data available!</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- End of Main Content -->

      <Footer />
    </div>
    <!-- End of Content Wrapper -->
  </div>
</template>
<style>
/* Style for the table header */
.table-header {
  background-color: #00609d;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  word-wrap: break-word;
}

/* General table styles */
.table {
  table-layout: auto;
  /* Allow columns to adjust based on content */
  width: 100%;
}

.table th,
.table td {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
}

/* Ensure the table fits within the container */
.table-responsive {
  overflow-x: auto;
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  #reportTable tbody td,
  #reportTable1 tbody td,
  #reportTable2 tbody td,
  #reportTable3 tbody td,
  #reportTable4 tbody td,
  #reportTable6 tbody td,
  #reportTable7 tbody td,
  #reportTable8 tbody td,
  #reportTable9 tbody td {
    font-size: 12px;
    padding: 5px;
  }

  #reportTable tbody td,
  #reportTable1 tbody td,
  #reportTable2 tbody td,
  #reportTable3 tbody td,
  #reportTable4 tbody td,
  #reportTable6 thead th,
  #reportTable7 thead th,
  #reportTable8 thead th,
  #reportTable9 thead th {
    font-size: 12px;
    padding: 5px;
  }
}

@media (max-width: 576px) {
  #reportTable6 thead th:nth-child(n + 10),
  #reportTable6 tbody td:nth-child(n + 10),
  #reportTable7 thead th:nth-child(n + 10),
  #reportTable7 tbody td:nth-child(n + 10),
  #reportTable8 thead th:nth-child(n + 10),
  #reportTable8 tbody td:nth-child(n + 10),
  #reportTable9 thead th:nth-child(n + 10),
  #reportTable9 tbody td:nth-child(n + 10) {
    display: none;
  }
}

.dt-paging-button .page-link {
  color: #003e7d;
  background-color: #ffffff;
  border-color: #003e7d;
}

.dt-paging-button.page-item.disabled .page-link {
  color: #6c757d;
  background-color: #e9ecef;
  border-color: #ced4da;
}

.dt-paging-button.page-item.active .page-link {
  color: #ffffff;
  background-color: #003e7d;
  border-color: #003e7d;
}

.dt-paging-button .page-link:hover {
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #0056b3;
}

.dt-search {
  float: right;
  padding-bottom: 10px;
}

.dt-paging {
  padding-top: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#WeeklyReportTable {
  border-collapse: separate !important;
}

#WeeklyReportTable thead th {
  background-color: #00609d;
  color: white !important;
  text-align: center;
  padding: 25px;
  white-space: nowrap;
  font-weight: bold;
  font-size: 14px;
}

.dataTables_processing {
  display: none !important;
}

#WeeklyReportTable tbody td {
  padding: 25px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

#AgencyBacklogTable {
  border-collapse: separate !important;
}

#AgencyBacklogTable thead th {
  background-color: #00609d;
  color: white !important;
  text-align: center;
  padding: 25px;
  white-space: nowrap;
  font-weight: bold;
  font-size: 14px;
}

.dataTables_processing {
  display: none !important;
}

#AgencyBacklogTable tbody td {
  padding: 25px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

.dt-search {
  float: right;
  padding-bottom: 10px;
}

.dt-paging {
  padding-top: 7px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  #WeeklyReportTable tbody td {
    font-size: 12px;
    padding: 5px;
  }

  #WeeklyReportTable thead th {
    font-size: 12px;
    padding: 5px;
  }
}

.table-responsive {
  overflow-x: auto;
  width: 100% !important;
}

@media (max-width: 576px) {
  #WeeklyReportTable thead th:nth-child(n + 10),
  #WeeklyReportTable tbody td:nth-child(n + 10) {
    display: none;
  }
}

@media (max-width: 768px) {
  #AgencyBacklogTable tbody td {
    font-size: 12px;
    padding: 5px;
  }

  #AgencyBacklogTable thead th {
    font-size: 12px;
    padding: 5px;
  }
}

.table-responsive {
  overflow-x: auto;
}

@media (max-width: 576px) {
  #AgencyBacklogTable thead th:nth-child(n + 10),
  #AgencyBacklogTable tbody td:nth-child(n + 10) {
    display: none;
  }
}

.dt-paging-button page-link {
  background-color: #003e7d !important;
  color: white !important;
}

/* Change the color of the pagination buttons */
.dt-paging-button .page-link {
  color: #003e7d;
  /* Text color */
  background-color: #ffffff;
  /* Background color */
  border-color: #003e7d;
  /* Border color */
}

.dt-paging-button page-link {
  background-color: #003e7d !important;
  color: white !important;
}

/* Change the color of the pagination buttons */
.dt-paging-button .page-link {
  color: #003e7d;
  /* Text color */
  background-color: #ffffff;
  /* Background color */
  border-color: #003e7d;
  /* Border color */
}

/* Change the color of disabled pagination buttons */
.dt-paging-button.page-item.disabled .page-link {
  color: #6c757d;
  /* Text color for disabled state */
  background-color: #e9ecef;
  /* Background color for disabled state */
  border-color: #ced4da;
  /* Border color for disabled state */
}

/* Change the color of active pagination button */
.dt-paging-button.page-item.active .page-link {
  color: #ffffff;
  /* Text color for active state */
  background-color: #003e7d;
  /* Background color for active state */
  border-color: #003e7d;
  /* Border color for active state */
}

/* Hover effects */
.dt-paging-button .page-link:hover {
  color: #0056b3;
  /* Text color on hover */
  background-color: #e9ecef;
  /* Background color on hover */
  border-color: #0056b3;
  /* Border color on hover */
}

.dt-search {
  float: right;
  padding-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#custom-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

@media (max-width: 576px) {
  .card-title {
    font-size: 14px !important;
  }

  .btn-outline-primary {
    font-size: 12px !important;
  }
}

.separator-dashed {
  border-top: 1px dashed #d1d3d4;
  margin: 15px 0;
}

.apexcharts-legend {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Force 2 columns */
  gap: 10px;
  justify-items: start;
  /* Align items to the left */
  align-items: start;
  text-align: left;
}

.apexcharts-legend-series {
  margin-bottom: 5px;
  /* Spacing below each item */
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  /* Adjust size */
  font-weight: bold;
  color: #333;
  /* Adjust color */
  pointer-events: none;
  /* Ensure it doesn't interfere with chart interaction */
}
</style>
<script>
import Topbar from "@/components/common/Topbar.vue";
import Footer from "@/components/common/Footer.vue";
import SideBar from "@/components/common/Navbar.vue";
// import "datatables.net-bs5";
import "datatables.net-dt/js/dataTables.dataTables";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.css";
import "@/assets/vendor/datatables/jquery.dataTables.min.js";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { TOP_VEHICLE_QUERY, YtdTollSummary, GET_WEEK } from "@/graphql";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApexCharts from "vue3-apexcharts";
import { Chart } from "chart.js";
import pdfMake from "pdfmake/build/pdfmake";
// import UtilsService from "@/services/utils.service.js";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import "bootstrap-datepicker";
// import ReportInfoService from "@/services/Report.service.js";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
export default {
  name: "LandingPage",
  components: {
    Topbar,
    Footer,
    SideBar,
    apexchart: ApexCharts,
    Bar,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      authenticated: false,
      Transponder: [],
      transponderNumber: "",
      Transactions: [],
      loading: false,
      temp_delete: false,
      offset: 0,
      limit: 10,
      week_id: null,
      year: null,
      weeks: [],
      status: 0,
      statusSpend: 4,
      agencies: "",
      Transactions: "",
      totalUnits: "",
      tollSpend: "",
      agencyData: [],
      agencies1: "",
      Transactions1: "",
      totalUnits1: "",
      tollSpend1: "",
      agencyData1: [],
      ytdTollSummary: [],
      reportdata: [],
      reportdata0: [],
      reportdata1: [],
      reportdata2: [],
      reportdata3: [],
      reportdata4: [],

      reportdataSpend: [],
      ytdTollSpend: "",
      ytdTrailersSpend: "",
      ytdAfpSpend: "",
      ytdTomSpend: "",
      ytdDspMMbtSpend: "",
      ytdOwnedAssetCount: "",
      ytdRentalAssetCount: "",
      ytdCitationsCount: "",
      impoundCount: "",
      series: [],
      chartOptions: {
        chart: {
          type: "donut",
          toolbar: {
            show: true,
          },
        },
        colors: this.getUniqueColors(35),
        labels: [],
        legend: {
          show: true,
          position: "right", // Legend positioned to the right
          horizontalAlign: "center",
          itemMargin: {
            horizontal: 10,
            vertical: 5,
          },
          fontSize: "14px",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%", // Adjust size if necessary to make room for the text
            },
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                width: "80%",
              },
              legend: {
                position: "right",
              },
            },
          },
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "70%",
              },
              legend: {
                position: "right",
              },
            },
          },
        ],
      },

      chartData: {
        labels: [], // Labels for the x-axis (agencies)
        datasets: [], // Data for the bars (report types)
      },
      chartBar2Options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function (tooltipItems) {
                return tooltipItems[0].label;
              },
              label: function (tooltipItem) {
                const dataset = tooltipItem.dataset;
                const datasetLabel = dataset.label || "";
                const value = dataset.data[tooltipItem.dataIndex];
                return `${datasetLabel}: $${value}`;
              },
              footer: function (tooltipItems) {
                return "";
              },
            },
            displayColors: true,
          },
        },
        scales: {
          x: {
            stacked: false,
            title: {
              display: true,
              text: "Month",
            },
            ticks: {
              callback: function (value, index) {
                return this.getLabelForValue(index);
              },
            },
          },
          y: {
            stacked: false,
            beginAtZero: true,
            title: {
              display: true,
              text: "Amount",
            },
            ticks: {
              callback: function (value) {
                return `$${value}`;
              },
            },
          },
        },
      },
      chartData1: {
        labels: [], // Labels for the x-axis (agencies)
        datasets: [], // Data for the bars (report types)
      },
      chartBarOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
          tooltip: {
            mode: "index", // Ensure all datasets at the hovered index are shown
            intersect: false, // Show tooltip when hovering near the bar group
            callbacks: {
              title: function (tooltipItems) {
                // Return the x-axis label (agency name) for the tooltip title
                return tooltipItems[0].label; // Assumes all tooltips are for the same x-axis value
              },
              label: function (tooltipItem) {
                // Format the tooltip label for each dataset
                const dataset = tooltipItem.dataset;
                const datasetLabel = dataset.label || "";
                const value = dataset.data[tooltipItem.dataIndex];
                return `${datasetLabel}: $${value}`;
              },
              footer: function (tooltipItems) {
                // Combine the footer content if needed
                return ""; // Leave empty or add footer content if required
              },
            },
            displayColors: true, // Show color boxes next to each item in the tooltip
          },
        },
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: "Weeks",
            },
            ticks: {
              callback: function (value, index) {
                return this.getLabelForValue(index);
              },
            },
            grid: {
              display: true,
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            title: {
              display: true,
              text: "Amount",
            },
            ticks: {
              callback: function (value) {
                return `$${value}`;
              },
            },
          },
        },
      },
    };
  },
  watch: {
    week_id() {
      this.updateReports();
    },
    year(newVal, oldVal) {
      Promise.all([this.updateReports(), this.ReportInfoYear()]);
    },
    series() {
      if (this.$refs.chart) {
        this.$refs.chart.updateSeries(this.series, true);
      }
    },
    "chartOptions.labels"() {
      if (this.$refs.chart) {
        this.$refs.chart.updateOptions(
          {
            labels: this.chartOptions.labels ?? [],
          },
          true
        );
      }
    },
  },
  apollo: {
    getWeeks: {
      query: GET_WEEK,
    },
  },
  methods: {
    async updateReports() {
      await Promise.all([this.ReportInfoCard(), this.TableData()]);
    },
    async ReportInfoYear() {
      const response = await this.$apollo.query({
        query: YtdTollSummary,
        variables: {
          year: this.year,
          weekId: this.week_id,
        },
      });
      this.ytdTollSummary =
        response.data.getAmazonAccountSummary.ytdTollSummary;
    },
    currentWeek() {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
      const week1 = new Date(date.getFullYear(), 0, 4);
      const weekNumber =
        Math.round(
          ((date.getTime() - week1.getTime()) / 86400000 -
            3 +
            ((week1.getDay() + 6) % 7)) /
            7
        ) + 1;

      this.week_id = weekNumber;
      this.year = date.getFullYear();
    },

    async statusChange(tabIndex) {
      this.status = tabIndex;

      await this.ReportInfo(tabIndex);
    },
    async TableData() {
      const response = await this.$apollo.query({
        query: TOP_VEHICLE_QUERY,
        variables: {
          year: this.year,
          weekId: this.week_id,
        },
      });

      this.reportdata0 =
        response.data.getAmazonTopVehicles.afpTopVehicles || [];

      this.reportdata1 =
        response.data.getAmazonTopVehicles.powerUnitTopVehicles || [];

      this.reportdata2 =
        response.data.getAmazonTopVehicles.trailersTopVehicles || [];

      this.reportdata3 =
        response.data.getAmazonTopVehicles.tomTopVehicles || [];

      this.reportdata4 =
        response.data.getAmazonTopVehicles.dspMMbtTopVehicles || [];

      this.ReportInfo(this.status);
    },
    async ReportInfo(status) {
      const tableSelectors = [
        "#reportTable0",
        "#reportTable1",
        "#reportTable2",
        "#reportTable3",
        "#reportTable4",
      ];

      let currentStatus = 0;

      if (status !== undefined) {
        currentStatus = status;
      }

      const tableName = tableSelectors[currentStatus];

      setTimeout(() => {
        let columns = [
          { data: "asset_type" },
          { data: "license_plate" },
          { data: "transponder" },
          { data: "state_code" },
          { data: "owner" },
          { data: "equipment_id" },
          { data: "toll" },
        ];

        const tableDataMap = {
          0: this.reportdata0,
          1: this.reportdata1,
          2: this.reportdata2,
          3: this.reportdata3,
          4: this.reportdata4,
        };

        $(tableName).DataTable({
          data: tableDataMap[currentStatus] ?? [],
          columns: columns,
          responsive: true,
          dom: "Blfrtip",
          destroy: true,
          buttons: [
            {
              extend: "csv",
              text: '<i class="fa fa-file-excel"></i>',
              className: "btn btn-sm btn-outline-info mb-3 text-success",
              action: function newexportaction(e, dt, button, config) {
                self.loading = true;
                fetchData(0, tollData.recordsTotal).then(() => {
                  var self = this;
                  var oldStart = dt.settings()[0]._iDisplayStart;
                  dt.one("preXhr", function (e, s, data) {
                    data.start = 0;
                    data.length = 2147483647;

                    dt.one("preDraw", function (e, settings) {
                      if (button[0].className.indexOf("buttons-csv") >= 0) {
                        $.fn.dataTable.ext.buttons.csvHtml5.available(
                          dt,
                          config
                        )
                          ? $.fn.dataTable.ext.buttons.csvHtml5.action.call(
                              self,
                              e,
                              dt,
                              button,
                              config
                            )
                          : $.fn.dataTable.ext.buttons.csvFlash.action.call(
                              self,
                              e,
                              dt,
                              button,
                              config
                            );
                      }
                      dt.one("preXhr", function (e, s, data) {
                        settings._iDisplayStart = oldStart;
                        data.start = oldStart;
                      });
                      setTimeout(dt.ajax.reload, 0);
                      return false;
                    });
                  });
                });
                self.loading = false;
              },
            },
            {
              extend: "pdf",
              text: '<i class="fa fa-file-pdf"></i>',
              className: "btn btn-sm btn-outline-info mb-3 text-danger",
              action: function newexportaction(e, dt, button, config) {
                // Open Loader
                fetchData(0, tollData.recordsTotal).then(() => {
                  var self = this;
                  var oldStart = dt.settings()[0]._iDisplayStart;
                  dt.one("preXhr", function (e, s, data) {
                    // Just this once, load all data from the server...
                    data.start = 0;
                    // data.length = self?.result?.dailyScansServerSide?.data;
                    data.length = 2147483647;
                    // self.refetch({ limit: 2147483647 });

                    dt.one("preDraw", function (e, settings) {
                      // e.data = data;
                      if (button[0].className.indexOf("buttons-pdf") >= 0) {
                        $.fn.dataTable.ext.buttons.pdfHtml5.available(
                          dt,
                          config
                        )
                          ? $.fn.dataTable.ext.buttons.pdfHtml5.action.call(
                              self,
                              e,
                              dt,
                              button,
                              config
                            )
                          : $.fn.dataTable.ext.buttons.pdfFlash.action.call(
                              self,
                              e,
                              dt,
                              button,
                              config
                            );
                      }
                      dt.one("preXhr", function (e, s, data) {
                        // DataTables thinks the first item displayed is index 0, but we're not drawing that.
                        // Set the property to what it was before exporting.
                        settings._iDisplayStart = oldStart;
                        data.start = oldStart;
                      });
                      // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
                      setTimeout(dt.ajax.reload, 0);
                      // Prevent rendering of the full data to the DOM
                      return false;
                    });
                  });
                  // Requery the server with the new one-time export settings
                  // dt.ajax.reload();
                });
              },
            },
            {
              extend: "print",
              text: '<i class="fa fa-print"></i>',
              className: "btn btn-sm btn-outline-primary mb-3 text-secondary",
              action: function newexportaction(e, dt, button, config) {
                // Open Loader
                fetchData(0, tollData.recordsTotal).then(() => {
                  var self = this;
                  var oldStart = dt.settings()[0]._iDisplayStart;
                  dt.one("preXhr", function (e, s, data) {
                    data.start = 0;
                    data.length = 2147483647;

                    dt.one("preDraw", function (e, settings) {
                      if (button[0].className.indexOf("buttons-print") >= 0) {
                        $.fn.dataTable.ext.buttons.print.action(
                          e,
                          dt,
                          button,
                          config
                        );
                      }
                      dt.one("preXhr", function (e, s, data) {
                        settings._iDisplayStart = oldStart;
                        data.start = oldStart;
                      });
                      setTimeout(dt.ajax.reload, 0);
                      return false;
                    });
                  });
                });
              },
            },
            {
              extend: "colvis",
              text: '<i class="fa fa-eye"></i>',
              className: "btn btn-sm btn-outline-secondary mb-3 text-primary",
            },
          ],
          language: {
            oPaginate: {
              sNext: '<i class="fa fa-forward"></i>',
              sPrevious: '<i class="fa fa-backward"></i>',
              sFirst: '<i class="fa fa-step-backward"></i>',
              sLast: '<i class="fa fa-step-forward"></i>',
            },
          },
        });
      }, 300);
    },

    async ReportInfoCard() {
      const response = await this.$apollo.query({
        query: YtdTollSummary,
        variables: {
          year: this.year,
          weekId: this.week_id,
        },
      });
      this.agencies = response.data.getAmazonAccountSummary.agencyData;
      this.Piechart();
      this.tollSpendByMonth =
        response.data.getAmazonAccountSummary.tollSpendByMonth;
      this.prepareChartData();
      this.tollSpentByWeekChartData =
        response.data.getAmazonAccountSummary.weeklyTollSpendChart;
      this.tollSpentByWeekChart();
    },
    async Piechart() {
      try {
        const pieData = this.agencies || [];

        // Check if pieData has valid data
        if (pieData.length === 0) {
          return;
        }

        // Ensure that value is always a number
        this.series = pieData.map((item) => {
          let value = item.value || 0; // Default to 0 if value is undefined or null

          try {
            value = value
              ? parseFloat(value?.toString().replace(/[$,]/g, ""))
              : 0;
          } catch (err) {
            value = 0;
          }

          return value;
        });

        // Ensure that labels are always defined
        this.chartOptions = structuredClone(this.chartOptions);
        (this.chartOptions.labels = pieData.map((item) => item.label || "")),
          this.$nextTick(() => {
            const chartRef = this.$refs.chart;
            if (chartRef && chartRef.chart) {
              chartRef.chart.updateOptions(
                { labels: this.chartOptions.labels ?? [] },
                true
              );
              chartRef.chart.updateSeries(this.series, true);
            } else {
              console.warn("Chart component is not yet available.");
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    getUniqueColors(count) {
      const baseColors = [
        "rgb(187,228,255)",
        "rgb(2,166,209)",
        "rgb(5,116,206)",
        "rgb(239,71,111)",
        "rgb(255,209,102)",
        "rgb(6,214,160)",
        "rgb(172,78,221)",
        "rgb(255,140,62)",
        "rgb(0,188,212)",
        "rgb(194,24,91)",
        "rgb(230,74,25)",
        "rgb(103,58,183)",
        "rgb(255,87,34)", // Orange
        "rgb(124,77,255)", // Deep Purple
        "rgb(67,160,71)", // Green
        "rgb(255,235,59)", // Yellow
        "rgb(96,125,139)", // Blue Gray
        "rgb(233,30,99)", // Pink
        "rgb(156,39,176)", // Purple
        "rgb(3,169,244)", // Light Blue
        "rgb(0,150,136)", // Teal
        "rgb(76,175,80)", // Light Green
        "rgb(255,152,0)", // Amber
        "rgb(244,67,54)", // Red
        "rgb(158,158,158)", // Gray
        "rgb(0,188,212)", // Cyan
      ];
      return baseColors.slice(0, count);
    },
    async prepareChartData() {
      try {
        const rawData = this.tollSpendByMonth;

        // Extract unique report types for datasets
        const reportTypes = Array.from(
          new Set(
            rawData.flatMap((item) => item.value.map((val) => val.report_type))
          )
        );
        const colors = this.getColors();
        // Extract unique months for labels
        const months = rawData.map((item) => item.label);

        // Create datasets for each report type
        const datasets = reportTypes.map((reportType, index) => ({
          label: reportType,
          data: months.map((month) => {
            const item = rawData.find((data) => data.label === month);
            const report = item?.value.find(
              (val) => val.report_type === reportType
            );
            return report
              ? parseFloat(report.amount.replace(/[^0-9.-]+/g, ""))
              : 0;
          }),
          backgroundColor: colors[index % colors.length], // Assign a specific color to each stack
        }));

        this.chartData = {
          labels: months,
          datasets: datasets,
        };
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    },
    async tollSpentByWeekChart() {
      try {
        const rawData = this.tollSpentByWeekChartData;

        // Extract unique report types for datasets
        const reportTypes = Array.from(
          new Set(
            rawData.flatMap((item) => item.value.map((val) => val.report_type))
          )
        );
        const colors = this.getColors();
        // Extract unique months for labels
        const months = rawData.map((item) => item.label);

        // Create datasets for each report type
        const datasets = reportTypes.map((reportType, index) => ({
          label: reportType,
          data: months.map((month) => {
            const item = rawData.find((data) => data.label === month);
            const report = item?.value.find(
              (val) => val.report_type === reportType
            );
            return report
              ? parseFloat(report.amount.replace(/[^0-9.-]+/g, ""))
              : 0;
          }),
          backgroundColor: colors[index % colors.length], // Assign a specific color to each stack
        }));

        this.chartData1 = {
          labels: months,
          datasets: datasets,
        };
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    },

    getColors() {
      return [
        "rgba(0, 96, 157, 1)", // Persian Blue
        "rgba(4, 166, 223, 0.5)", // Vivid Cerulean
        "rgba(140, 161, 174, 0.5)", // Cadet Grey
        "rgba(68, 123, 183, 0.5)", // Space Cadet
        "rgba(0, 132, 187, 0.7)",
        // Add more colors if there are more report types
      ];
    },
    getRandomColor() {
      const colors = [
        "rgba(68, 123, 183, 0.5)",
        "rgba(0, 96, 157, 1)",
        "rgba(4, 166, 223, 0.5)",
        "rgba(140, 161, 174, 0.5)",
      ];

      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    },
    getRandomColorPie() {
      return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )}, ${Math.floor(Math.random() * 255)}, 0.5)`;
    },
  },

  mounted() {
    this.statusChange(0);
    this.currentWeek();
    this.updateReports();
    $(this.$refs.yearPicker)
      .datepicker({
        format: "yyyy",
        viewMode: "years",
        minViewMode: "years",
        autoclose: true,
      })

      .on("changeDate", (e) => {
        this.year = parseInt(e.format(0, "yyyy"));
      });
  },
};
</script>
