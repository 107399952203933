import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ResetPassword from "../views/resetPassword.vue";
import Cookies from "js-cookie";

// Listen for the 'beforeunload' event
window.addEventListener("beforeunload", (event) => {
  // Check if the tab is being closed
  if (event.clientX < 0 && event.clientY < 0) {
    // Clear token and user information when the tab is closed
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
});
const authGuard = (to, from, next) => {
  const isAuthenticated = localStorage.getItem("token") !== null;
  if (
    isAuthenticated &&
    (to.path === "/login" || to.path === "/" || to.path === "/forget")
  ) {
    next("/dashboard");
  } else if (isAuthenticated || to.path === "/login" || to.path === "/forget") {
    next();
  } else {
    next("/login");
  }
};

const routes = [
  { path: "/", name: "login", component: Login },
  { path: "/register", name: "Register", component: Register },
  { path: "/login", component: Login },
  {
    path: "/forget",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/all-transponder",
    name: "Amaozon Transponder",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Alltransponder.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponders" },
  },
  {
    path: "/dispute",
    name: "Amaozon Disputes",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dispute.vue"),
    beforeEnter: authGuard,
    meta: { title: "Dispute" },
  },
  {
    path: "/citaion",
    name: "Citation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Citation.vue"),
    beforeEnter: authGuard,
    meta: { title: "Citation" },
  },
  {
    path: "/state",
    name: "State",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/State.vue"),
    beforeEnter: authGuard,
    meta: { title: "State" },
  },
  {
    path: "/all-report",
    name: "All AAP Report",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Allaapreport.vue"),
    beforeEnter: authGuard,
    meta: { title: "AllAAPReport" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: authGuard,
    meta: { title: "Dashboard" },
  },
  {
    path: "/agency",
    name: "Agency",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Agency.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agency" },
  },
  {
    path: "/vehicle",
    name: "Vehicle",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/vehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle" },
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Transactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transactions" },
  },
  {
    path: "/asset-masterfile",
    name: "Asset Masterfile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AssetMasterfile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Asset Masterfile" },
  },
  {
    path: "/transponder-fulfillment",
    name: "Transponder Fulfillment",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/transponderFulfilment.vue"
      ),
    beforeEnter: authGuard,
    meta: { title: "Transponder Fulfillment" },
  },
  {
    path: "/transponder-request",
    name: "Transponder Request",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/transponderRequest.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Request" },
  },

  {
    path: "/profile",
    name: "My Profile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Profile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Profile" },
  },
  {
    path: "/client-upload",
    name: "CLient Upload",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ClientUpload.vue"),
    beforeEnter: authGuard,
    meta: { title: "clientupload" },
  },
  {
    path: "/toll-agency-report",
    name: "TOll Agency Report",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TollAgency.vue"),
    beforeEnter: authGuard,
    meta: { title: "Weekly Report" },
  },
  {
    path: "/weekly-report",
    name: "Weekly Report",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WeeklyReport.vue"),
    beforeEnter: authGuard,
    meta: { title: "Weekly Report" },
  },

  {
    path: "/not-found",
    name: "Not Found",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authGuard);

export default router;
